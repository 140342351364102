



























































import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { defineComponent } from '@nuxtjs/composition-api'
import { getHrefByLinkType } from '@/composition/getHrefByLinkType'
import Link from '@/components/Link.vue'

@Component
class BlockListItem extends Vue {
  @Prop() readonly item: Object = {}
  @Prop() readonly isExtended: boolean = false
  @Prop() readonly largeText: Boolean = false
  @Prop() readonly isListItems: boolean = false
  static options: any
}

export default defineComponent({
  name: 'BlockListItem',
  components: {
    Link,
  },
  props: BlockListItem.options.props,

  setup() {
    return { getHrefByLinkType }
  },
})
