var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"list-item",class:{
    'no-padding-bottom': _vm.isListItems,
    'no-border': _vm.item.hideBorder === 'Да',
  }},[_c('div',{staticClass:"list-item-row"},[(_vm.isExtended)?_c('div',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.item.years),expression:"item.years"}],staticClass:"list-item-years word-breaking"}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"list-item-title"},[(_vm.item.iconType)?_c('div',{staticClass:"icon-wrapper"},[_c('svg-icon',{attrs:{"name":("icon/" + (_vm.item.iconType))}})],1):_vm._e(),_vm._v(" "),(_vm.item.value)?_c('a',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.item.valueLabel),expression:"item.valueLabel"}],staticClass:"label text--card-link word-breaking",class:("" + (_vm.isExtended ? 'link--basic ' : 'link--basic-bold')),attrs:{"href":("" + (_vm.getHrefByLinkType(_vm.item))),"rel":"noopener"}}):_c('p',{staticClass:"label label-red word-breaking",class:{
          'text--basic': _vm.isExtended && !_vm.largeText,
          'text--basic-bold': !_vm.isExtended && !_vm.largeText,
          'text--card-head': _vm.largeText,
        },domProps:{"innerHTML":_vm._s(_vm.item.valueLabel)}})]),_vm._v(" "),(!_vm.isListItems)?_c('div',{staticClass:"list-item-description"},[(_vm.item.title)?_c('p',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.item.title),expression:"item.title"}],staticClass:"text--basic label word-breaking"}):_vm._e()]):_c('ul',{staticClass:"list-item-elements"},_vm._l((_vm.item.list),function(element,elementIndex){return _c('li',{key:elementIndex},[_c('Link',{staticClass:"text--list-elements",attrs:{"url":element.linkUrl,"append":element.append}},[_vm._v("\n          "+_vm._s(element.linkText)+"\n        ")])],1)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }