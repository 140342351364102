











































import { Vue, Component, Prop } from 'nuxt-property-decorator'
import {
  defineComponent,
  ref,
  SetupContext,
  computed,
} from '@nuxtjs/composition-api'
import { VueSlideToggle } from 'vue-slide-toggle'
import BlockText from '@/components/BlockText.vue'
import { useColoredTitle } from '@/composition/coloredTitle'
import { CardWithToggle } from '@/contracts/CardWithToggle'
import BlockList from '@/components/BlockList.vue'
import BlockListArchiveItems from '@/components/BlockListArchiveItems.vue'

@Component
class BlockBannerText extends Vue implements CardWithToggle {
  @Prop() readonly textPreview: string = ''
  @Prop() readonly textToOpen: string = ''
  @Prop() readonly title: string = ''
  @Prop() readonly isAngle: Boolean = false
  @Prop() readonly blockListTitle: string = ''
  @Prop() readonly blockListData: ReadonlyArray<any> = []
  @Prop() readonly documentTitle: string = ''
  @Prop() readonly documentsData: ReadonlyArray<any> = []
  static options: any
}

export default defineComponent({
  components: { BlockText, BlockList, BlockListArchiveItems, VueSlideToggle },
  props: BlockBannerText.options.props,
  setup(props: any, ctx: SetupContext) {
    const { title = '' } = props
    const buttonText = computed(() => ({
      open: ctx.root.$store.getters['settings/btnShowMore'],
      close: ctx.root.$store.getters['settings/btnRollUp'],
    }))
    const isOpen = ref(false)
    const duration = ref(300)
    const onClick = () => {
      isOpen.value = !isOpen.value
    }

    return {
      ...useColoredTitle(title),
      buttonText,
      isOpen,
      duration,
      onClick,
    }
  },
})
