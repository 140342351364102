





























import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { defineComponent } from '@nuxtjs/composition-api'
import { IBlockList } from '@/contracts/BlockList'
import { IFormContact } from '@/contracts/IFormContactBlock'
import BlockText from '@/components/BlockText.vue'
import BlockListItem from '@/components/BlockListItem.vue'

@Component
class BlockList extends Vue implements IBlockList {
  @Prop() readonly isExtended: boolean = false
  @Prop() readonly isListItems: boolean = false
  @Prop() readonly disableDelay: boolean = false
  @Prop() readonly title: string = ''
  @Prop() readonly text: string = ''
  @Prop() readonly largeText: Boolean = false
  @Prop() readonly list: ReadonlyArray<IFormContact> = []
  static options: any
}

export default defineComponent({
  name: 'BlockList',
  components: {
    BlockText,
    BlockListItem,
  },
  props: BlockList.options.props,
})
